import { addNotify } from 'features/notify';

export const actionTypes = {
  GET_TRANSFERS: 'bets/GET_TRANSFERS',
  ACTION_PROCESSING: 'bets/ACTION_PROCESSING',
};

export const getTransfers = (params) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

  const { locale } = getState().locale;
  const { api } = extra;
  const response = await api.bets.getTransfers(params);
  const errorMessage = locale.hasOwnProperty(response.codeStatus)
    ? locale[response.codeStatus]
    : locale.defaultMessage;
  if (response.success) {
    dispatch({ type: actionTypes.GET_TRANSFERS, payload: response.data });
  } else {
    dispatch(addNotify(errorMessage, 'error'));
  }
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};
