import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import arrowSvg from './img/arrow.svg';

import './Header.scss';

const Header = ({ location, history }) => {
  const b = block('header');
  const locale = useSelector((state) => state.locale.locale);
  const isNeedBackButton = useMemo(
    () => location.pathname !== '/',
    [location.pathname]
  );

  const onClick = useCallback(() => {
    if (isNeedBackButton) history.goBack();
  }, [history, isNeedBackButton]);
  const text =
    locale[
      location.pathname.substring(
        0,
        location.pathname.lastIndexOf('/') === 0
          ? location.pathname.length
          : location.pathname.lastIndexOf('/')
      )
    ];

  return (
    <div className={b()}>
      MINIADMIN <span>|</span> {locale.menu}
    </div>
  );
};

export default withRouter(Header);
