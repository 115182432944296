import { useCallback, useMemo, useState } from 'react';

const useSorterByKeys = ({ keys, targetArray }) => {
  const staticSorts = useMemo(
    () => Object.fromEntries(keys.map((key) => [key, 0])),
    [keys]
  );

  const [sort, changeSort] = useState({ ...staticSorts });

  const handleChangeSort = useCallback(
    (type) => {
      changeSort((data) => ({
        ...staticSorts,
        [type]: data[type] > 0 ? -1 : 1,
      }));
    },
    [staticSorts]
  );

  const sortedList = useMemo(
    () =>
      [...targetArray].sort((a, b) => {
        return (
          keys
            .map((key) =>
              sort[key] !== 0
                ? sort[key] > 0
                  ? a[key] - b[key]
                  : b[key] - a[key]
                : 0
            )
            .filter((el) => el !== 0)[0] ?? 0
        );
      }),
    [keys, sort, targetArray]
  );

  return { sortedList, handleChangeSort };
};

export default useSorterByKeys;
