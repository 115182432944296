import BaseApi from '../BaseApi';
import { UserApiConverter } from './UserApiConverter';

class UserApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.api = `${baseUrl}/api`;
    this.converter = new UserApiConverter();
  }

  getUsersList = (search) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/user_list${search ? `?search=${search}` : ''}`,
      null,
      null,
      this.converter.convertUsersList
    );

  generatePassword = (isUser) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.api}/user/password/generate?is_user=${isUser}`,
      null,
      null,
      this.converter.convertGeneratedPassword
    );

  getSettings = () =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.api}/site/settings`,
      null,
      null,
      this.converter.convertSettings
    );

  createUser = (requestBody) =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/create_user`,
      requestBody
    );

  changeBanState = (id, value) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/change_attribute`, {
      operation: 'change_ban',
      target_id: id,
      is_banned: value,
    });

  changeUserBalance = (id, amount, isOutcome) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/change_attribute`, {
      operation: 'change_balance',
      target_id: +id,
      amount: +amount,
      is_outcome: isOutcome,
    });

  resetPassword = (id, new_password) =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/change_attribute`,
      {
        operation: 'change_password',
        target_id: id,
        new_password: new_password,
      },
      null,
      this.converter.convertNewPassword
    );
}

export default UserApi;
