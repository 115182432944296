import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Input from 'components/Input';
import Button from 'components/Button';

import { actions } from 'features/users/redux';

import './CreateUser.scss';

const CreateUser = ({ history }) => {
  const b = block('create-user');
  const dispatch = useDispatch();
  const [username, changeUsername] = useState('');
  const [password, changePassword] = useState('');
  const locale = useSelector((state) => state.locale.locale);
  const actionProcessing = useSelector((state) => state.users.actionProcessing);
  const onClick = useCallback(() => {
    if (!actionProcessing)
      dispatch(
        actions.createUser(username, password, () => history.replace('/'))
      );
  }, [actionProcessing, dispatch, password, username, history]);

  const disabled = username.length < 1 || password.length < 1;

  return (
    <div className={b()}>
      <div className={b('item')}>
        <div className={b('title')}>{locale.username}</div>
        <Input
          placeholder={locale.username}
          value={username}
          onChange={(e) => changeUsername(e.currentTarget.value)}
        />
      </div>
      <div className={b('item')}>
        <div className={b('title')}>{locale.password}</div>
        <Input
          placeholder={locale.password}
          value={password}
          onChange={(e) => changePassword(e.currentTarget.value)}
          type="password"
          isPassword
        />
      </div>
      <div className={b('button')}>
        <Button disabled={disabled} callBack={onClick} text={locale.create} />
      </div>
    </div>
  );
};

export default withRouter(CreateUser);
