import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import DateInput from 'components/DateInput';
import Button from 'components/Button';

import './BetsFilter.scss';

const BetsFilter = (props) => {
  const b = block('slots-bets-filter');
  const { locale, beginDate, endDate, onChangeBeginDate, onChangeEndDate } =
    props;
  return (
    <div className={b()}>
      <div className={b('dates')}>
        <div className={b('dates-item')}>
          <span className={b('dates-label')}>{locale.from}</span>
          <div className={b('dates-input')}>
            <DateInput
              dateFormat="yyyy-MM-dd"
              selected={beginDate}
              onChange={onChangeBeginDate}
            />
          </div>
        </div>
        <div className={b('dates-item')}>
          <span className={b('dates-label')}>{locale.to}</span>
          <div className={b('dates-input')}>
            <DateInput
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={onChangeEndDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BetsFilter.propTypes = {
  locale: PropTypes.object.isRequired,
  beginDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onChangeBeginDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
};

export default BetsFilter;
