import React from 'react';
import block from 'bem-cn';

import spinnerIMG from './img/logo.png';

import './Spinner.scss';

const b = block('spinner');

const Spinner = ({ isLoading, foolViewport }) =>
  isLoading ? (
    <div className={b({ foolViewport })}>
      <img className={b('logo')} src={spinnerIMG} alt="" />
    </div>
  ) : null;

export default Spinner;
