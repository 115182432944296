import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { actions } from '../redux';
import TreeRow from './TreeRow/TreeRow';
import Input from 'components/Input';
import Select from 'components/Select';
import Paginator from 'components/Paginator';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import './Tree.scss';

const pageCount = 8;

const Tree = () => {
  const b = block('tree');
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const { users: usersDataList, actionProcessing } = useSelector(
    (state) => state.tree,
    shallowEqual
  );
  const { id: targetUser } = useSelector((state) => state.auth, shallowEqual);

  const [data, setData] = useState({
    id: '',
    username: '',
    role: null,
  });

  const role = data.role ?? { name: locale.all, value: 'all' };

  const pages = Math.ceil(usersDataList?.length / pageCount) || 1;

  const [page, changePage] = useState(1);

  const roles = useMemo(
    () =>
      Object.entries(locale.roles)
        .map(([key, val]) => ({ value: key, name: val }))
        .reduce(
          (acc, role) => [...acc, role],
          [{ name: locale.all, value: 'all' }]
        ),
    [locale]
  );

  const rows = useMemo(
    () =>
      usersDataList
        .slice(pageCount * (page - 1), pageCount * page)
        .map((row) => <TreeRow key={row.userId} row={row} />),
    [usersDataList, page]
  );

  const changeValue = useCallback((e) => {
    const { name, value } = e.currentTarget;
    setData((state) => ({ ...state, [name]: value }));
  }, []);

  const handleRoleChange = useCallback(
    (value) => {
      const role =
        value === 'all'
          ? { name: locale.all, value: 'all' }
          : { name: locale.roles[value], value };
      setData((state) => ({ ...state, role }));
    },
    [locale]
  );

  const getUsers = useCallback(() => {
    const info = {
      target_role: role.value !== 'all' ? role.value : undefined,
      username: data.username,
      sub_user_id: data.id,
      target_user: targetUser,
    };
    const queryParams = Object.entries(info)
      .map((el) => el.join('='))
      .join('&');
    dispatch(actions.getUsersByUserId(queryParams));
  }, [role, targetUser]);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />

      <div className={b('filters')}>
        <div className={b('input')}>
          <span className={b('label')}>{locale.username}</span>
          <Input
            value={data.username}
            onChange={changeValue}
            placeholder={locale.username}
            name="username"
          />
        </div>
        <div className={b('input')}>
          <span className={b('label')}>ID</span>
          <Input
            value={data.id}
            onChange={changeValue}
            placeholder="ID"
            name="id"
            type="number"
          />
        </div>
        <div className={b('input')}>
          <span className={b('label')}>{locale.role}</span>
          <Select items={roles} activeItem={role} onChange={handleRoleChange} />
        </div>
        <div className={b('button')}>
          <Button
            disabled={actionProcessing}
            callBack={actionProcessing ? (f) => f : getUsers}
            text={locale.display}
          />
        </div>
      </div>
      {!!usersDataList.length ? (
        <>
          <div className={b('list')}>{rows}</div>
          <div className={b('paginator')}>
            <Paginator
              pages={pages}
              currentPage={page}
              locale={locale}
              changePage={changePage}
            />
          </div>
        </>
      ) : (
        <h3 className={b('no-users')}>{locale.noUsers}</h3>
      )}
    </div>
  );
};

export default Tree;
