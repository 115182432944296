export class TreeApiConverter {
  convertUser = (data) => ({
    username: data.username,
    id: data.id,
    createdAt: data.created_at,
    balance: data.balance,
    parentId: data.parent_id,
    role: data.role,
  });

  convertUsersById = (data) => {
    return data.map((t) => {
      return {
        userId: t.user_id,
        username: t.username,
        balance: t.balance,
        players: t.players,
        subusers: t.subusers,
        role: t.role,
        isBanned: t.is_banned,
      };
    });
  };
}
