const actionTypes = {
  SET_LOCALE: 'locale/SET_LOCALE',
};

function setLocale({ locale }) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_LOCALE, payload: { locale } });
  };
}

export { actionTypes, setLocale };
