import React, { forwardRef, useMemo } from 'react';
import block from 'bem-cn';
import DatePicker from 'react-datepicker';

import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import { formateDate } from 'shared/helpers/';

import calendarSVG from './img/calendar.svg';
import arrowSVG from './img/arrow.svg';

import 'react-datepicker/dist/react-datepicker.css';

import './DateInput.scss';

const b = block('date-input');

const DateInput = ({
  dateFormat = 'yyyy-MM-dd',
  selected,
  isValid,
  onChange,
  color = 'default',
  ...restProps
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className={b('custom-date-input')}
      onClick={(e) => {
        if (onClick && !restProps.disabled) onClick(e);
      }}
      ref={ref}
    >
      <SVGInline
        className={b('custom-date-input-calendar').toString()}
        svg={calendarSVG}
      />
      <span className={b('custom-date-input-value')}>{formateDate(value)}</span>
      <SVGInline
        className={b('custom-date-input-arrow').toString()}
        svg={arrowSVG}
      />
    </div>
  ));

  const validState = useMemo(() => {
    if (selected === null || isValid === undefined) return 1;
    if (isValid) return 2;
    return 3;
  }, [isValid, selected]);

  return (
    <div className={b({ validState, color })}>
      <DatePicker
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};

DateInput.propTypes = {
  isValid: PropTypes.bool,
  dateFormat: PropTypes.string,
  color: PropTypes.string,
  selected: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateInput;
