import React, { useMemo } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useFooterSwitchers } from './hooks/useFooterSwitchers';

import './Footer.scss';

const Footer = ({ locale }) => {
  const b = block('footer');
  const { switchers, handleSetSwitchers } = useFooterSwitchers();

  const switchersElements = useMemo(
    () =>
      switchers.map(([key, switcher]) => (
        <div
          key={key}
          onClick={() => handleSetSwitchers(key)}
          className={b('switcher', { open: switcher.open })}
        >
          <div className={b('switcher-current')}>
            {switcher.title}
            <SVGInline
              svg={switcher.svg}
              className={b('switcher-image').toString()}
            />
          </div>
          {switcher.open && switcher.component && (
            <div
              onClick={(e) => e.stopPropagation()}
              className={b('switcher-items')}
            >
              {switcher.component}
            </div>
          )}
        </div>
      )),
    [switchers]
  );

  return (
    <footer className={b()}>
      <div className={b('title')}>{locale.other}</div>
      <div className={b('content')}>{switchersElements}</div>
    </footer>
  );
};

export default Footer;
