import React, { useState, useCallback, useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { actions } from '../../redux';

import Input from 'components/Input';
import Button from 'components/Button';
import DateInput from 'components/DateInput';
import Paginator from 'components/Paginator';
import TransactionsTable from './Table';

import './TransactionHistory.scss';

const TransactionHistory = () => {
  const b = block('transaction-history');
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const {
    transactions,
    count,
    totalWithdrawal,
    totalDeposit,
    actionProcessing,
  } = useSelector((state) => state.transaction, shallowEqual);

  const [data, setData] = useState({
    username: '',
    fromUsername: '',
    toUsername: '',
    fromDate: new Date(dayjs().add(-1, 'month')),
    toDate: new Date(dayjs().add(1, 'day')),
  });
  const [page, changePage] = useState(1);

  const pagination = useMemo(
    () => ({
      offset: page - 1,
      limit: 8,
      pages() {
        return Math.ceil(count / this.limit);
      },
    }),
    [count, page]
  );

  const changeValue = useCallback((e) => {
    const { name, value } = e.currentTarget;
    setData((state) => ({ ...state, [name]: value }));
  }, []);

  const changeDate = useCallback((value, type) => {
    const name = type ? 'fromDate' : 'toDate';
    setData((state) => ({ ...state, [name]: value }));
  }, []);

  const getTransactions = useCallback(() => {
    dispatch(
      actions.getTransactions({
        ...data,
        fromDate: dayjs(data.fromDate).format('YYYY-MM-DD'),
        toDate: dayjs(data.toDate).format('YYYY-MM-DD'),
        page: pagination.offset,
        count: pagination.limit,
      })
    );
  }, [dispatch, data, pagination]);

  useEffect(() => {
    getTransactions();
  }, [page]);

  return (
    <div className={b()}>
      <div className={b('filters')}>
        <div className={b('dates')}>
          <div className={b('dates-item')}>
            <span className={b('label')}>{locale.from}</span>
            <div className={b('dates-input')}>
              <DateInput
                dateFormat="yyyy-MM-dd"
                selected={data.fromDate}
                onChange={changeDate}
              />
            </div>
          </div>
          <div className={b('dates-item')}>
            <span className={b('label')}>{locale.to}</span>
            <div className={b('dates-input')}>
              <DateInput
                dateFormat="yyyy-MM-dd"
                selected={data.toDate}
                onChange={(date) => changeDate(date, 1)}
              />
            </div>
          </div>
        </div>
        <div className={b('input')}>
          <span className={b('label')}>{locale.username}</span>
          <Input
            value={data.username}
            onChange={changeValue}
            placeholder={locale.username}
            name="username"
          />
        </div>
        <div className={b('input')}>
          <span className={b('label')}>{locale.fromUsername}</span>
          <Input
            value={data.fromUsername}
            onChange={changeValue}
            placeholder={locale.fromUsername}
            name="fromUsername"
          />
        </div>
        <div className={b('input')}>
          <span className={b('label')}>{locale.toUsername}</span>
          <Input
            value={data.toUsername}
            onChange={changeValue}
            placeholder={locale.toUsername}
            name="toUsername"
          />
        </div>
        <div className={b('button')}>
          <Button
            disabled={actionProcessing}
            callBack={actionProcessing ? (f) => f : getTransactions}
            text={locale.display}
          />
        </div>
        <div className={b('totals')}>
          <span className={b('totals-text')}>
            {locale.paymentTypes[2]}: {totalDeposit}
          </span>
          <span className={b('totals-text')}>
            {locale.paymentTypes[1]}: {totalWithdrawal}
          </span>
        </div>
      </div>
      <div className={b('table')}>
        <TransactionsTable items={transactions} locale={locale} />
      </div>
      {!!transactions.length && (
        <div className={b('paginator')}>
          <Paginator
            pages={pagination.pages()}
            currentPage={page}
            locale={locale}
            changePage={changePage}
          />
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
