export class BetsApiConverter {
  convertTransferList = ({ data, totalCount }) => {
    return {
      count: totalCount,
      transfers: data.map((t) => ({
        userId: t?.user_id,
        username: t?.username,
        date: t?.date,
        action: t?.action,
        provider: t?.provider,
        amount: t?.amount,
      })),
    };
  };
}
