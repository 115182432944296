import React, { useMemo } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useSorterByKeys from 'hooks/useSorterByKeys';
import SVGInline from 'react-svg-inline';
import sortSvg from './img/sort.svg';

import './Table.scss';

const sortKeys = ['action'];

const Table = ({ locale, items }) => {
  const b = block('bets-table');

  const { sortedList, handleChangeSort } = useSorterByKeys({
    keys: sortKeys,
    targetArray: items ?? [],
  });

  const bets = useMemo(
    () =>
      sortedList.map((t, idx) => (
        <tr key={idx} className={b('row')}>
          <td className={b('td')}>{t.userId}</td>
          <td className={b('td-separate')} />
          <td className={b('td')}>{locale.casinoStatuses[t.action]}</td>
          <td className={b('td-separate')} />
          <td className={b('td')}>{dayjs(t.date).format('DD.MM.YYYY')}</td>
          <td className={b('td-separate')} />
          <td className={b('td', { type: 'username' })}>{t.username}</td>
          <td className={b('td-separate')} />
          <td className={b('td')}>{t.provider}</td>
          <td className={b('td-separate')} />
          <td className={b('td')}>{t.amount}</td>
        </tr>
      )),
    [sortedList, locale]
  );

  const statusElements = items.map((bet, idx) => (
    <div
      key={idx + bet.action}
      className={b('statuses-item', { status: bet.action })}
    />
  ));

  return (
    <div className={b()}>
      <div className={b('statuses')}>{statusElements}</div>
      <div className={b('table-wrapper')}>
        {bets.length ? (
          <table cellSpacing="0" cellPadding="0" className={b('table')}>
            <thead className={b('header')}>
              <tr>
                <th className={b('th')}>{locale.userId}</th>
                <th className={b('th-separate')} />
                <th className={b('th', { type: 'action' })}>
                  {locale.action}
                  <SVGInline
                    svg={sortSvg}
                    onClick={() => handleChangeSort(sortKeys[0])}
                    className={b('sort').toString()}
                  />
                </th>
                <th className={b('th-separate')} />
                <th className={b('th')}>{locale.date}</th>
                <th className={b('th-separate')} />
                <th className={b('th')}>{locale.name}</th>
                <th className={b('th-separate')} />
                <th className={b('th')}>{locale.provider}</th>
                <th className={b('th-separate')} />
                <th className={b('th')}>{locale.amount}</th>
              </tr>
            </thead>

            <tbody className={b('bets')}>{bets}</tbody>
          </table>
        ) : (
          <h3 className={b('no-bets')}>{locale.noBets}</h3>
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  locale: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      userId: PropTypes.number,
      action: PropTypes.string,
      date: PropTypes.string,
      username: PropTypes.string,
      provider: PropTypes.string,
      amount: PropTypes.number,
    })
  ).isRequired,
};

export default Table;
