import BaseApi from '../BaseApi';
import { TreeApiConverter } from './TreeApiConverter';

class TreeApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new TreeApiConverter();
  }

  changeUserStateByAction = ({ id, value, name }) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/balance_tree`, {
      action: name,
      state: value,
      target_user: id,
    });

  createUser = (requestBody) =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/create_user`,
      requestBody
    );

  getUser = (id) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/user/${id}/`,
      null,
      null,
      this.converter.convertUser
    );

  getUsersByUserId = (data) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/balance_tree?${data}`,
      null,
      null,
      this.converter.convertUsersById
    );
}

export default TreeApi;
