import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import langSVG from '../img/lang.svg';
import pasSVG from '../img/pas.svg';
import logoutSVG from '../img/out.svg';

import { actions as authActions } from 'features/Auth';
import { Locale } from 'features/locale';
import ChangePas from 'features/ChangePas';

export const useFooterSwitchers = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale);
  const isAuth = useSelector((state) => state.auth.isAuth);

  const staticSwitchers = useMemo(
    () => ({
      locale: {
        open: false,
        component: <Locale />,
        title: 'changeLang',
        svg: langSVG,
        withAuth: false,
      },
      password: {
        open: false,
        component: <ChangePas />,
        title: 'changePas',
        svg: pasSVG,
        withAuth: true,
      },
      logout: {
        open: false,
        title: 'logout',
        svg: logoutSVG,
        withAuth: true,
      },
    }),
    []
  );

  const [switchers, setSwitchers] = useState({ ...staticSwitchers });

  const handleSetSwitchers = useCallback((type) => {
    if (type === 'logout') return dispatch(authActions.logOut());
    setSwitchers((data) => ({
      ...data,
      [type]: { ...data[type], open: !data[type].open },
    }));
  }, []);

  useEffect(() => {
    setSwitchers({ ...staticSwitchers });
  }, [isAuth]);

  const filteredSwitchers = useMemo(
    () =>
      Object.entries(switchers)
        .filter((el) => isAuth || !el[1].withAuth)
        .map(([key, switcher]) => [
          key,
          { ...switcher, title: locale[switcher.title] },
        ]),
    [switchers, isAuth, locale]
  );

  return {
    switchers: filteredSwitchers,
    handleSetSwitchers,
  };
};
