export class UserApiConverter {
  convertUsersList = (data) => ({
    users: data?.users || [],
    quantity: data?.quantity || 0,
    totalBalance: data?.balance_sum || 0,
  });

  convertNewPassword = (data) => data.new_password;

  convertGeneratedPassword = (data) => data.strong_password;

  convertSettings(data) {
    const { agent, player } = data.password_settings;
    const converterData = {
      passwordSetting: {
        ...data.password_settings,
        agent: {
          ...agent,
          specialCharacters: agent.special_characters,
          minLength: agent.min_length,
        },
        player: {
          ...player,
          specialCharacters: player.special_characters,
          minLength: player.min_length,
        },
      },
    };
    return converterData;
  }
}
