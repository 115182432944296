import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import ArrowSVG from './img/arrow.svg';

import './Select.scss';

const Select = ({ items, activeItem, onChange }) => {
  const b = block('select');
  const [isOpen, changeOpen] = useState(false);
  const locale = useSelector((state) => state.locale.locale);

  const itemsList = items.map((item) => (
    <div
      key={item.value}
      className={b('item')}
      onClick={() => onChange(item.value)}
    >
      {locale[item.name] ?? item.name}
    </div>
  ));
  return (
    <div
      className={b({ open: isOpen })}
      onClick={() => changeOpen(!isOpen)}
      onMouseLeave={() => changeOpen(false)}
    >
      <div className={b('item', { current: true })}>
        {activeItem.name}
        <SVGInline className={b('arrow').toString()} svg={ArrowSVG} />
      </div>
      {isOpen && <div className={b('items-list')}>{itemsList}</div>}
    </div>
  );
};

export default Select;
