import React, { useState, useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';

import Button from 'components/Button';
import DateInput from 'components/DateInput';
import Paginator from 'components/Paginator';
import Spinner from 'components/Spinner';

import { actions } from '../redux';

import './Total.scss';

const b = block('total');

const pageCount = 8;

const Total = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale);
  const {
    totalInfo,
    users: userList,
    actionProcessing,
  } = useSelector((state) => state.total);
  const { name, role } = useSelector((state) => state.auth, shallowEqual);
  const [fromDate, changeFromDate] = useState(
    new Date(dayjs().add(-1, 'month'))
  );
  const [toDate, changeToDate] = useState(new Date(dayjs().add(1, 'day')));
  const [page, changePage] = useState(1);
  const pages = Math.ceil(userList?.length / pageCount) || 1;

  const callBack = useCallback(
    () =>
      dispatch(
        actions.getTotal(
          dayjs(fromDate).format('YYYY-MM-DD'),
          dayjs(toDate).format('YYYY-MM-DD')
        )
      ),
    [fromDate, toDate, dispatch]
  );

  useEffect(() => {
    callBack();
  }, []);

  const onUserClick = useCallback(
    (id) => {
      if (!actionProcessing) {
        dispatch(
          actions.getUserData(
            id,
            dayjs(fromDate).format('YYYY-MM-DD'),
            dayjs(toDate).format('YYYY-MM-DD')
          )
        );
      }
    },
    [actionProcessing, dispatch, fromDate, toDate]
  );

  const cashier = useMemo(() => {
    return (
      +role === 1 && (
        <div key={name} className={b('row')}>
          <span className={b('td')}>{name}</span>
          <span className={b('td')}>{totalInfo.total}</span>
          <span className={b('td')}>{totalInfo.credits}</span>
          <span className={b('td')}>{totalInfo.debits}</span>
        </div>
      )
    );
  }, [role, totalInfo, name]);

  const users = useMemo(
    () =>
      userList.length === 1 && !userList[0].userId
        ? null
        : userList.slice(pageCount * (page - 1), pageCount * page).map((t) => (
            <div
              key={t.userId}
              className={b('row', {})}
              onClick={() => onUserClick(t.userId)}
            >
              <span className={b('td')}>{t.username}</span>
              <span className={b('td')}>{t.total}</span>
              <span className={b('td')}>{t.credits}</span>
              <span className={b('td')}>{t.debits}</span>
            </div>
          )),
    [onUserClick, userList, page]
  );

  const isAnyUsers = users.length > 0 || cashier;

  return (
    <section className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('dates')}>
        <div className={b('dates-item')}>
          <span className={b('dates-label')}>{locale.from}</span>
          <div className={b('dates-input')}>
            <DateInput
              dateFormat="yyyy-MM-dd"
              selected={fromDate}
              onChange={changeFromDate}
            />
          </div>
        </div>
        <div className={b('dates-item')}>
          <span className={b('dates-label')}>{locale.to}</span>
          <div className={b('dates-input')}>
            <DateInput
              dateFormat="yyyy-MM-dd"
              selected={toDate}
              onChange={changeToDate}
            />
          </div>
        </div>
      </div>
      <div className={b('button')}>
        <Button
          disabled={actionProcessing}
          callBack={actionProcessing ? (f) => f : callBack}
          text={locale.display}
        />
      </div>
      {isAnyUsers ? (
        <article className={b('result')}>
          <div className={b('result-header')}>
            <span className={b('td')}>{locale.username}</span>
            <span className={b('td')}>{locale.total}</span>
            <span className={b('td')}>{locale.deposit}</span>
            <span className={b('td')}>{locale.withdraw}</span>
          </div>
          {cashier}
          {users}
          <div className={b('paginator')}>
            <Paginator
              pages={pages}
              currentPage={page}
              locale={locale}
              changePage={changePage}
            />
          </div>
        </article>
      ) : (
        <h3 className={b('no-users')}>{locale.noUsers}</h3>
      )}
    </section>
  );
};

export default Total;
