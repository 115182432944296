import React from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
import Select from 'components/Select';
import { providers } from './data';

import './ProviderFilter.scss';

const ProviderFilter = (props) => {
  const b = block('provider-filter');
  const { locale, onChange, filters } = props;

  const handleProvidersChange = (value) => {
    const target = providers.find((item) => item.value === value);
    const provider =
      target.value === 'all' ? { ...target, name: locale.all } : target;
    onChange({ ...filters, provider });
  };
  return (
    <section className={b()}>
      <div className={b('text')}>{locale.provider}</div>
      <Select
        items={providers}
        activeItem={filters.provider}
        onChange={handleProvidersChange}
      />
    </section>
  );
};

ProviderFilter.propTypes = {
  locale: PT.object.isRequired,
  onChange: PT.func.isRequired,
  filters: PT.exact({
    provider: PT.exact({ name: PT.string, value: PT.string, type: PT.string }),
  }).isRequired,
};

export default ProviderFilter;
