import React, { useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import Information from './Information/Information';

import './TreeRow.scss';

const b = block('tree-row');

const TreeRow = ({ row }) => {
  const { userId, username, balance, role } = row;
  const [infoModalOpened, openInfoModal] = useState(false);
  const user = useSelector((state) => state.auth);
  const locale = useSelector((state) => state.locale.locale, shallowEqual);

  const handleOpenInfoModal = () => {
    openInfoModal((open) => !open);
  };

  return (
    <>
      {infoModalOpened && (
        <Modal onClose={handleOpenInfoModal}>
          <Information data={row} onClose={handleOpenInfoModal} />
        </Modal>
      )}
      <div className={b()}>
        <div className={b('block')}>
          <span className={b('block-text', { type: 'name' })}>{username}</span>
          <div onClick={handleOpenInfoModal} className={b('block-button')}>
            {locale.info}
          </div>
        </div>
        <div className={b('block')}>
          <span className={b('block-text')}>
            {locale.id}: {userId}
          </span>
          <span className={b('block-text')}>
            {locale.balance}: {balance} {user.currency}
          </span>
          <span className={b('block-text')}>
            {locale.role}: {locale.roles[role][0]}
          </span>
        </div>
      </div>
    </>
  );
};

export default TreeRow;
