import React, { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import { addNotify } from 'features/notify';

import './Copy.scss';

const b = block('copy');

const Copy = ({ children, value }) => {
  const dispatch = useDispatch();

  const locale = useSelector((state) => state.locale.locale, shallowEqual);

  const onCopy = useCallback(() => {
    dispatch(addNotify(locale.passwordCopied, 'success'));
  }, [dispatch, locale]);

  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      {children}
    </CopyToClipboard>
  );
};

export default Copy;
