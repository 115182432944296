import dayjs from 'dayjs';
import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'tree/ACTION_PROCESSING',
  GET_USERS_BY_USER_ID_SUCCESS: 'tree/GET_USERS_BY_USER_ID_SUCCESS',
  GET_USER: 'tree/GET_USER',
};

const getUsersByUserId = (data) => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.tree.getUsersByUserId(data);

  if (response.success) {
    dispatch({
      type: actionTypes.GET_USERS_BY_USER_ID_SUCCESS,
      payload: response.data,
    });
  } else {
    dispatch(addNotify('Error', 'error'));
  }
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const getUser = (id) => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.tree.getUser(id);
  if (response.success) {
    dispatch({
      type: actionTypes.GET_USER,
      payload: response.data,
    });
  } else {
    dispatch(addNotify('Error', 'error'));
  }
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const banUser =
  ({ id, value }) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.tree.changeUserStateByAction({
      id,
      value,
      name: 'ban',
    });

    if (response.success) {
      const text = value ? locale.banSuccess : locale.unbanSuccess;
      dispatch(addNotify(text, 'success'));
    } else {
      dispatch(addNotify('Error', 'error'));
    }
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };

export { actionTypes, getUsersByUserId, banUser, getUser };
