/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import searchSvg from './img/search.svg';
import Button from 'components/Button';

import './Search.scss';
import { shallowEqual, useSelector } from 'react-redux';

const b = block('search');

const Search = ({
  color = 'default',
  fontSize = 14,
  type = 'text',
  measure = '',
  isPassword = false,
  name,
  onChange,
  ...restProps
}) => {
  const [searchString, setSearchString] = useState('');
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  return (
    <div className={b('wrapper')}>
      <div className={b({ color, fontSize })}>
        <SVGInline svg={searchSvg} className={b('icon').toString()} />
        <input
          onChange={(e) => setSearchString(e.currentTarget.value)}
          className={b('native')}
          type={type}
          name={name}
          {...restProps}
        />
      </div>
      <div className={b('button')}>
        <Button text={locale.search} callBack={() => onChange(searchString)} />
      </div>
    </div>
  );
};

export default Search;
