import React, { useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';

import { actions } from '../redux';
import useProviderFilters from 'hooks/useProviderFilters';

import Paginator from 'components/Paginator';
import Button from 'components/Button';
import Input from 'components/Input';
import Spinner from 'components/Spinner';
import BetsFilter from './BetsFilter';
import ProviderFilter from './ProviderFilter';
import Table from './Table';

import './Bets.scss';

const format = 'YYYY-MM-DD';

const Bets = () => {
  const b = block('bets');
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale);
  const { transfers, count, actionProcessing } = useSelector(
    (state) => state.bets,
    shallowEqual
  );

  const [userId, changeUserId] = useState(0);
  const [page, changePage] = useState(1);
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'month')),
    endDate: new Date(dayjs().add(1, 'day')),
  });

  const pagination = {
    offset: page - 1,
    limit: 8,
    pages() {
      return Math.ceil(count / this.limit);
    },
  };

  const { filters, setFilters } = useProviderFilters(locale);

  const getTransfers = useCallback(() => {
    const { beginDate, endDate } = date;

    const type =
      filters.provider.type !== 'all' ? filters.provider.type : undefined;
    const provider =
      filters.provider.value !== 'all' ? filters.provider.value : undefined;

    const info = {
      user_id: userId,
      from_date: dayjs(beginDate).format(format),
      to_date: dayjs(endDate).format(format),
      page: pagination.offset,
      count: pagination.limit,
      type,
      provider,
    };

    const queryParams = [...Object.entries(info)]
      .map((el) => el.join('='))
      .join('&');
    dispatch(actions.getTransfers(queryParams));
  }, [date, page, filters, userId, pagination]);

  const onChangeBeginDate = (value) => {
    setDate({ ...date, beginDate: value });
  };
  const onChangeEndDate = (value) => {
    setDate({ ...date, endDate: value });
  };
  const onChangeUserId = (e) => {
    changeUserId(e.currentTarget.value);
  };

  useEffect(() => {
    getTransfers();
  }, [page]);

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('filters')}>
        <BetsFilter
          locale={locale}
          beginDate={date.beginDate}
          endDate={date.endDate}
          onChangeBeginDate={onChangeBeginDate}
          onChangeEndDate={onChangeEndDate}
        />

        <ProviderFilter
          filters={filters}
          locale={locale}
          onChange={setFilters}
        />

        <div className={b('input')}>
          <Input
            value={userId}
            onChange={onChangeUserId}
            type="number"
            placeholder={locale.userId}
          />
        </div>
        <div className={b('button')}>
          <Button callBack={getTransfers} text={locale.display} type="submit" />
        </div>
      </div>
      <div className={b('table')}>
        <Table locale={locale} items={transfers} />
      </div>
      {!!transfers.length && (
        <div className={b('paginator')}>
          <Paginator
            pages={pagination.pages()}
            currentPage={page}
            locale={locale}
            changePage={changePage}
          />
        </div>
      )}
    </div>
  );
};

export default Bets;
