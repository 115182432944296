import React, { useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import arrowSvg from './img/arrow.svg';

import './Paginator.scss';

const itemsOnPage = 3;

const Paginator = ({ pages, currentPage, changePage }) => {
  const b = block('slots-paginator');
  const [isOpen, changeOpen] = useState(false);
  const leftArrow = currentPage > 1;
  const rightArrow = currentPage < pages;
  const slideStart = itemsOnPage * (Math.ceil(currentPage / itemsOnPage) - 1);
  const slideEnd = itemsOnPage * Math.ceil(currentPage / itemsOnPage);

  const handleArrows = (type) => {
    if (leftArrow && type < 2) changePage(currentPage - 1);
    if (rightArrow && type > 1) changePage(currentPage + 1);
  };

  return (
    <div className={b()} onClick={() => changeOpen(!isOpen)}>
      <SVGInline
        svg={arrowSvg}
        onClick={() => handleArrows(1)}
        className={b('arrow', { active: leftArrow }).toString()}
      />
      <div className={b('pages')}>
        {new Array(pages)
          .fill(null)

          .map((el, i) => {
            const page = i + 1,
              isActive = page === currentPage;
            return (
              <li
                key={i}
                className={b('page', { active: isActive })}
                onClick={isActive ? null : () => changePage(page)}
              >
                {page}
              </li>
            );
          })
          .slice(slideStart, slideEnd)}
      </div>
      <SVGInline
        svg={arrowSvg}
        onClick={() => handleArrows(2)}
        className={b('arrow', { active: rightArrow }).toString()}
      />
    </div>
  );
};

Paginator.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  locale: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default Paginator;
