import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/users/redux';
import Input from 'components/Input';
import Button from 'components/Button';

import './ChangePas.scss';

const ChangePas = () => {
  const b = block('change-pas');
  const { id } = useSelector((state) => state.auth, shallowEqual);
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const { actionProcessing } = useSelector(
    (state) => state.users,
    shallowEqual
  );

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    password: '',
    confirmedPassword: '',
  });

  const handleSetForm = (e) => {
    const { name, value } = e.currentTarget;
    setForm((data) => ({ ...data, [name]: value }));
  };

  const resetPassword = () => {
    dispatch(actions.resetPassword({ userId: id, newPassword: form.password }));
  };

  const disabled =
    actionProcessing ||
    form.password.length < 1 ||
    form.confirmedPassword !== form.password;

  return (
    <div className={b()}>
      <label className={b('input')}>
        <span className={b('input-text')}>{locale.newPassword}</span>
        <Input
          type="password"
          value={form.password}
          onChange={handleSetForm}
          placeholder={locale.newPassword}
          name="password"
          isPassword
          isValid={form.password.length > 0}
        />
      </label>
      <label className={b('input')}>
        <span className={b('input-text')}>{locale.confirmNewPassword}</span>
        <Input
          type="password"
          value={form.confirmedPassword}
          onChange={handleSetForm}
          placeholder={locale.confirmNewPassword}
          name="confirmedPassword"
          isPassword
          isValid={form.confirmedPassword === form.password}
        />
      </label>
      <div className={b('button')}>
        <Button
          text={locale.save}
          disabled={disabled}
          callBack={resetPassword}
        />
      </div>
    </div>
  );
};

export default ChangePas;
