import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/tree';
import { actions as usersActions } from 'features/users';

import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Input from 'components/Input';
import Copy from 'components/Copy';
import Modal from 'components/Modal';

import passSVG from '../../img/pass.svg';
import minusSVG from '../../img/minus.svg';
import plusSVG from '../../img/plus.svg';
import copySVG from '../../img/copy.svg';

import './Information.scss';

const b = block('information');

const Information = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const { userId: targetId } = data;
  const { user, actionProcessing } = useSelector(
    (state) => state.tree,
    shallowEqual
  );

  const { newPassword, actionProcessing: usersActionProcessing } = useSelector(
    (state) => state.users,
    shallowEqual
  );
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const [amount, changeAmount] = useState('');
  const [passwordModalOpened, changePasswordModal] = useState();

  const onBanClick = (isBanned) => {
    const value = !!isBanned;
    dispatch(actions.banUser({ id: user.id, value }));
  };

  const handleChangeAmount = (e) => {
    changeAmount(e.currentTarget.value);
  };

  const onPaymentClick = (isTopUp) => {
    if (amount > 0) {
      dispatch(
        usersActions.changeUserBalance(user.id, amount, !isTopUp, changeAmount)
      ).then(() => dispatch(actions.getUser(targetId)));
    }
  };

  const handleOpenPasswordModal = () => {
    changePasswordModal((open) => !open);
  };

  const onResetClick = useCallback(() => {
    if (!usersActionProcessing)
      dispatch(
        usersActions.resetPassword({ userId: user.id, role: user.role })
      );
  }, [usersActionProcessing, user]);

  const fields = useMemo(
    () =>
      Object.entries(user).map(([key, val]) => {
        const value = key === 'role' ? locale.roles[val] : val;
        return (
          <div key={key} className={b('fields-field')}>
            <span className={b('fields-field-key')}>{locale[key]}:</span>
            <span className={b('fields-field-value')}>{value}</span>
          </div>
        );
      }),
    [user, locale]
  );

  useEffect(() => {
    if (targetId) {
      dispatch(actions.getUser(targetId));
    }
  }, []);

  if (!Object.keys(user).length) return <Spinner isLoading />;

  return (
    <>
      <Spinner isLoading={actionProcessing} />
      {passwordModalOpened && (
        <Modal onClose={handleOpenPasswordModal}>
          <div className={b()}>
            <Spinner isLoading={usersActionProcessing} />
            <div className={b('title')}>{locale.changePas}</div>
            <div className={b('password')}>
              <span className={b('password-label')}>{locale.password}</span>
              <div className={b('password-value')}>
                <span>{newPassword ?? ''}</span>
                <Copy value={newPassword ?? ''}>
                  <SVGInline className={b('copy').toString()} svg={copySVG} />
                </Copy>
              </div>
            </div>
            <Button text={locale.resetPassword} callBack={onResetClick} />
            <div onClick={handleOpenPasswordModal} className={b('close')}>
              {locale.close}
            </div>
          </div>
        </Modal>
      )}
      <div className={b()}>
        <div className={b('fields')}>{fields}</div>
        <div className={b('buttons')}>
          <Button
            text={locale.ban}
            color="red"
            callBack={() => onBanClick(1)}
          />
          <Button
            text={locale.unban}
            color="black"
            callBack={() => onBanClick(0)}
          />
          <div onClick={handleOpenPasswordModal} className={b('pass-button')}>
            <SVGInline
              className={b('pass-button-icon').toString()}
              svg={passSVG}
            />
            <Button text="" onClick={() => ''} />
          </div>
        </div>

        <div className={b('payment')}>
          <SVGInline
            className={b('payment-icon', { disabled: amount < 1 }).toString()}
            svg={minusSVG}
            onClick={() => onPaymentClick(false)}
          />
          <div className={b('payment-field')}>
            <Input
              placeholder={locale.amount}
              value={amount}
              onChange={handleChangeAmount}
              type="number"
            />
          </div>
          <SVGInline
            onClick={() => onPaymentClick(true)}
            className={b('payment-icon', { disabled: amount < 1 }).toString()}
            svg={plusSVG}
          />
        </div>

        <div onClick={onClose} className={b('close')}>
          {locale.close}
        </div>
      </div>
    </>
  );
};

Information.propTypes = {};

export default Information;
