const getPasswordSettingLocale = ({ setting, locale }) => {
  const { active, digits, letters, minLength, specialCharacters } = setting;
  const passReqLocale = locale.passwordRequirements;
  const passwordStrings = [
    minLength > 0 && `${passReqLocale.minLength} ${minLength}`,
    digits.amount > 0 && `${digits.amount} ${passReqLocale.digits}`,
    letters.amount > 0 && `${letters.amount} ${passReqLocale.letters}`,
    letters.lowercase > 0 && `${letters.lowercase} ${passReqLocale.lowercase}`,
    letters.uppercase > 0 && `${letters.uppercase} ${passReqLocale.uppercase}`,
    specialCharacters.amount > 0 &&
      `${specialCharacters.amount} ${passReqLocale.specialCharacters}`,
  ]
    .filter((el) => el)
    .join(', ');

  if (!active) return locale.disabledPassChange;
  return `${passReqLocale.root}: ${passwordStrings}`;
};

export default getPasswordSettingLocale;
