import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { actions as authAction } from 'features/Auth';

import homeSVG from './img/home.svg';
import reloadSVG from './img/reload.svg';

import './Subheader.scss';

const Subheader = () => {
  const b = block('subheader');
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale);
  const user = useSelector((state) => state.auth);

  const handleGetBalance = () => {
    if (!user.isProcess) dispatch(authAction.getBalance());
  };

  useEffect(() => {
    const value = setInterval(() => handleGetBalance(), 5000);
    return () => {
      clearInterval(value);
    };
  }, []);

  return (
    <article className={b()}>
      {locale.login} ({locale.id}: {user.id}): {user.balance} {user.currency}
      <div className={b('images')}>
        <SVGInline
          onClick={handleGetBalance}
          svg={reloadSVG}
          className={b('svg', { loading: user.isProcess }).toString()}
        />
        <Link to="/">
          <SVGInline svg={homeSVG} className={b('svg').toString()} />
        </Link>
      </div>
    </article>
  );
};

export default Subheader;
