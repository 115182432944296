const providers = [
  { value: 'all', name: 'all', type: 'all' },
  { value: 'ECONOMIX', name: 'Economix', type: 'slots' },
  { value: 'SLOTEGRATOR', name: 'Slotegrator', type: 'slots' },
  { value: 'SOFTGAMING', name: 'Softgaming', type: 'slots' },
  { value: 'PRAGMATIC-SLOTS', name: 'Pragmatic Slots', type: 'slots' },
  { value: 'FIABLE', name: 'Fiable', type: 'slots' },
  { value: 'EVOPLAY', name: 'Evoplay', type: 'slots' },
  { value: 'HABANERO', name: 'Habanero', type: 'slots' },
  { value: 'CASIMI', name: 'Casimi', type: 'slots' },
  { value: 'BSWGAMES', name: 'BSWGames', type: 'slots' },
  { value: 'EVOLUTION', name: 'Evolution', type: 'live-casino' },
  {
    value: 'PRAGMATIC-LIVE-CASINO',
    name: 'Pragmatic Live',
    type: 'live-casino',
  },
];

export { providers };
