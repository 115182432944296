import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import { languagesWithIcons } from 'services/locale';
import { actions } from 'features/userSettings';

import './Locale.scss';

const Locale = () => {
  const b = block('locale');
  const lang = useSelector((state) => state.userSettings.lang);
  const dispatch = useDispatch();
  const langItems = useMemo(
    () =>
      Object.entries(languagesWithIcons).map((temp) => (
        <div
          key={temp[0]}
          className={b('item', { active: lang === temp[0] })}
          onClick={() => dispatch(actions.changeLang(temp[0]))}
        >
          <img src={temp[1].icon} alt="" className={b('image')} />
          {temp[1].text}
        </div>
      )),
    [lang]
  );

  return <section className={b()}>{langItems}</section>;
};

export default Locale;
