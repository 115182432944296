export class TransactionApiConverter {
  convertTransfers = ({ data, count, total_deposit, total_withdrawal }) => {
    return {
      totalDeposit: total_deposit,
      totalWithdrawal: total_withdrawal,
      count: count,
      transactions: data.map((t) => ({
        username: t?.email,
        date: t?.date,
        amount: t?.amount,
        type: t?.type,
        userId: t?.user_id,
      })),
    };
  };
}
