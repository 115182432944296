import { actionTypes } from '../actions';
import { initialState } from '../initial';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case actionTypes.GET_SETTINGS:
      return {
        ...state,
        passwordSetting: action.payload.passwordSetting,
      };

    case actionTypes.GENERATE_PASSWORD:
      return {
        ...state,
        generatedPassword: action.payload,
      };
    default:
      return { ...state };
  }
}
