import { useState } from 'react';

const useProviderFilters = (locale) => {
  const staticFilters = {
    provider: { name: locale.all, value: 'all', type: 'all' },
  };

  const [filters, setFilters] = useState({ ...staticFilters });

  const initialFilters = filters.provider.name ? filters : staticFilters;

  return { filters: initialFilters, setFilters };
};

export default useProviderFilters;
