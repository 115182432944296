import React, { useEffect, useMemo, useCallback, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/users';
import { actions as treeActions } from 'features/tree';

import Input from 'components/Input';
import Copy from 'components/Copy';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import copySVG from '../img/copy.svg';

import './UserEdit.scss';

const pages = {
  payment: false,
  details: false,
};

const UserEdit = ({ match }) => {
  const b = block('user-edit');
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.tree, shallowEqual);
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const { actionProcessing, newPassword } = useSelector(
    (state) => state.users,
    shallowEqual
  );
  const [paymentValue, changePaymentValue] = useState('');
  const [currentPage, setCurrentPage] = useState({ ...pages, payment: true });

  const id = match.params.id;

  const onPaymentClick = useCallback(
    (isTopUp = false) => {
      if (paymentValue > 0) {
        dispatch(
          actions.changeUserBalance(
            id,
            paymentValue,
            !isTopUp,
            changePaymentValue
          )
        ).then(() => dispatch(treeActions.getUser(id)));
      }
    },
    [id, paymentValue]
  );

  const onBanClick = useCallback(
    (type) => {
      const value = !!type;
      if (!actionProcessing) dispatch(actions.changeBanState(id, value));
    },
    [actionProcessing, dispatch, id]
  );

  const handleSetCurrentPage = useCallback((page) => {
    setCurrentPage((data) => ({ ...pages, [page]: !data[page] }));
  }, []);

  const onResetClick = useCallback(() => {
    if (!actionProcessing)
      dispatch(actions.resetPassword({ userId: user.id, role: user.role }));
  }, [actionProcessing, user]);

  const pageSwitchers = useMemo(
    () =>
      Object.keys(pages).map((page) => (
        <div
          key={page}
          onClick={() => handleSetCurrentPage(page)}
          className={b('page-switchers-item', { active: currentPage[page] })}
        >
          {locale[page]}
        </div>
      )),
    [currentPage, locale]
  );

  useEffect(() => {
    if (id) {
      dispatch(treeActions.getUser(id));
    }
  }, []);

  if (!Object.keys(user).length) return <Spinner isLoading />;

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('upper-info')}>
        <div>
          <span>{locale.username}:</span> {user.username}
        </div>
        <div>
          <span>{locale.accountBalance}:</span> {user.balance} {user.currency}
        </div>
      </div>

      <div className={b('page-switchers')}>{pageSwitchers}</div>

      {currentPage.details && (
        <>
          <div className={b('info')}>
            <div className={b('info-item')}>
              <div className={b('info-title')}>ID</div>
              <div className={b('info-value')}>{user.id}</div>
            </div>
            <div className={b('info-item')}>
              <div className={b('info-title')}>{locale.nickname}</div>
              <div className={b('info-value')}>{user.username}</div>
            </div>
            <div className={b('info-item')}>
              <div className={b('info-title')}>{locale.balance}</div>
              <div className={b('info-value')}>
                {user.balance} {user.currency}
              </div>
            </div>
            <div className={b('info-item')}>
              <div className={b('info-title', { type: 'password' })}>
                {locale.password}
              </div>
              <div className={b('info-value', { type: 'password' })}>
                <span>{newPassword ?? ''}</span>
                <Copy value={newPassword ?? ''}>
                  <SVGInline className={b('copy').toString()} svg={copySVG} />
                </Copy>
              </div>
            </div>
          </div>

          <div className={b('buttons')}>
            <Button text={locale.resetPassword} callBack={onResetClick} />
            <Button
              text={locale.banUser}
              color="red"
              callBack={() => onBanClick(1)}
            />
            <Button
              text={locale.unbanUser}
              color="black"
              callBack={() => onBanClick(0)}
            />
          </div>
        </>
      )}

      {currentPage.payment && (
        <>
          <div className={b('info')}>
            <div className={b('info-item')}>
              <div className={b('info-title')}>{locale.amount}</div>
              <Input
                type="number"
                value={paymentValue}
                onChange={(e) => changePaymentValue(e.currentTarget.value)}
                placeholder={locale.amount}
              />
            </div>
          </div>

          <div className={b('buttons')}>
            <Button
              text={locale.topUp}
              disabled={paymentValue < 1}
              callBack={() => onPaymentClick(true)}
            />
            <Button
              text={locale.withdrawal}
              color="black"
              disabled={paymentValue < 1}
              callBack={() => onPaymentClick(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserEdit;
