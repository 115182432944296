import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'components/Input';
import { actions } from '../redux';

import './Auth.scss';
import Button from 'components/Button/Button';

const Auth = () => {
  const b = block('auth');
  const [username, changeUsername] = useState('');
  const [password, passwordUsername] = useState('');
  const locale = useSelector((state) => state.locale.locale);
  const { isProcess } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const signIn = useCallback(() => {
    if (!isProcess) {
      dispatch(actions.login(username, password));
    }
  }, [username, password, isProcess, dispatch]);

  const disabled = isProcess || username.length < 1 || password.length < 1;

  return (
    <section className={b()}>
      <div className={b('title')}>{locale.login}</div>
      <div className={b('fields')}>
        <div className={b('input-block')}>
          <div className={b('title-text')}>{`${locale.login}:`}</div>
          <Input
            value={username}
            placeholder={locale.login}
            onChange={(e) => changeUsername(e.currentTarget.value)}
          />
        </div>
        <div className={b('input-block')}>
          <div className={b('title-text')}>{`${locale.password}:`}</div>
          <Input
            value={password}
            placeholder={locale.password}
            onChange={(e) => passwordUsername(e.currentTarget.value)}
            type="password"
            isPassword
          />
        </div>
        <div className={b('button')}>
          <Button callBack={signIn} text={locale.login} disabled={disabled} />
        </div>
      </div>
    </section>
  );
};

export default Auth;
