import React, { useMemo } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import './TransactionsTable.scss';

const TransactionsTable = ({ locale, items }) => {
  const b = block('transactions-table');

  const transactions = useMemo(
    () =>
      items.map((t, idx) => (
        <div key={idx} className={b('row')}>
          <div className={b('td')}>{t.username}</div>
          <div className={b('td')}>{locale.paymentTypes[t.type]}</div>
          <div className={b('td')}>{dayjs(t.date).format('DD.MM.YYYY')}</div>
          <div className={b('td')}>{t.amount}</div>
        </div>
      )),
    [locale, items]
  );

  return (
    <div className={b()}>
      {transactions.length ? (
        <div className={b('table')}>
          <div className={b('header')}>
            <div className={b('td', { type: 'header' })}>{locale.username}</div>
            <div className={b('td', { type: 'header' })}>{locale.type}</div>
            <div className={b('td', { type: 'header' })}>{locale.date}</div>
            <div className={b('td', { type: 'header' })}>{locale.amount}</div>
          </div>

          <div className={b('transactions')}>{transactions}</div>
        </div>
      ) : (
        <h3 className={b('no-transactions')}>{locale.noTransactions}</h3>
      )}
    </div>
  );
};

TransactionsTable.propTypes = {
  locale: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      userId: PropTypes.number,
      type: PropTypes.number,
      date: PropTypes.string,
      username: PropTypes.string,
      amount: PropTypes.number,
    })
  ).isRequired,
};

export default TransactionsTable;
