import { shortLanguages } from 'services/locale';
import { actions as localeActions } from 'features/locale';

import dayjs from 'dayjs';

// импорт локализации dayjs для языков
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/ar';

const actionTypes = {
  CHANGE_LANG: 'userSettings/CHANGE_LANG',
  GENERATE_PASSWORD: 'userSettings/GENERATE_PASSWORD',
  GET_SETTINGS: 'userSettings/GET_SETTINGS',
};

function changeLang(lang) {
  return async (dispatch) => {
    const { locale } = await import(`services/locale/${lang}/index`); // code-splitting для словарей
    document.querySelector('html').lang = shortLanguages[lang];
    dayjs.locale(shortLanguages[lang]);
    dispatch({ type: actionTypes.CHANGE_LANG, payload: lang });
    dispatch(localeActions.setLocale({ locale }));
  };
}

function generatePassword(role = 0) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const isUser = +role === 0;
    const response = await api.user.generatePassword(isUser);
    dispatch({ type: actionTypes.GENERATE_PASSWORD, payload: response.data });
  };
}

function getSettings() {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const response = await api.user.getSettings();
    if (response.success) {
      dispatch({ type: actionTypes.GET_SETTINGS, payload: response.data });
    }
  };
}

export { actionTypes, changeLang, generatePassword, getSettings };
