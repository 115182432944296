/* eslint-disable no-unused-expressions */
import React, { useCallback, useMemo, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import passwordSVG from './img/password.svg';
import passwordOffSVG from './img/passwordOff.svg';

import './Input.scss';

const b = block('input');

const Input = ({
  color = 'default',
  fontSize = 14,
  type = 'text',
  measure = '',
  isPassword = false,
  name,
  ...restProps
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordClick = useCallback(
    () => setPasswordVisible(!passwordVisible && isPassword),
    [passwordVisible, isPassword]
  );

  const validState = useMemo(() => {
    if (
      String(restProps?.value).length === 0 ||
      restProps.isValid === undefined
    )
      return 1;
    if (restProps.isValid) return 2;
    return 3;
  }, [restProps.isValid, restProps.value]);

  return (
    <div className={b({ color, fontSize, validState })}>
      <input
        className={b('native', {
          type: isPassword ? 'password' : type,
          activePassword: isPassword && !passwordVisible,
        })}
        type={passwordVisible ? 'text' : type}
        name={name}
        {...restProps}
      />
      {isPassword && (
        <>
          <SVGInline
            svg={passwordSVG}
            className={b('password', { active: passwordVisible }).toString()}
            onClick={passwordClick}
          />
          <SVGInline
            svg={passwordOffSVG}
            className={b('password-off', {
              active: !passwordVisible,
            }).toString()}
            onClick={passwordClick}
          />
        </>
      )}
    </div>
  );
};

export default Input;
